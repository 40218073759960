import { forwardRef, LegacyRef } from 'react';
import * as styles from '../icon.styles';
import type { IconProps } from '../icon.model';
const OutlookPrimaryIcon = forwardRef(
    ({ title, titleId, color, borderRadius, ...props }: IconProps, ref?: LegacyRef<SVGSVGElement>) => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-75 -125 2000 2000"
            width={props.width}
            height={props.height}
            role="img"
            aria-hidden="true"
            className={styles.icon({
                ...props,
                borderRadius,
                color,
            })}
            fill="none"
            ref={ref}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                fill="#0A2767"
                d="M1831.083 894.25a40.88 40.88 0 0 0-19.503-35.131h-.213l-.767-.426-634.492-375.585a86 86 0 0 0-8.517-5.067 85.17 85.17 0 0 0-78.098 0 86 86 0 0 0-8.517 5.067l-634.49 375.585-.766.426c-19.392 12.059-25.337 37.556-13.278 56.948a41.35 41.35 0 0 0 14.257 13.868l634.492 375.585a96 96 0 0 0 8.517 5.068 85.17 85.17 0 0 0 78.098 0 96 96 0 0 0 8.517-5.068l634.492-375.585a40.84 40.84 0 0 0 20.268-35.685"
            />
            <path
                fill="#0364B8"
                d="M520.453 643.477h416.38v381.674h-416.38zM1745.917 255.5V80.908c1-43.652-33.552-79.862-77.203-80.908H588.204C544.552 1.046 510 37.256 511 80.908V255.5l638.75 170.333z"
            />
            <path fill="#0078D4" d="M511 255.5h425.833v383.25H511z" />
            <path fill="#28A8EA" d="M1362.667 255.5H936.833v383.25L1362.667 1022h383.25V638.75z" />
            <path fill="#0078D4" d="M936.833 638.75h425.833V1022H936.833z" />
            <path fill="#0364B8" d="M936.833 1022h425.833v383.25H936.833z" />
            <path fill="#14447D" d="M520.453 1025.151h416.38v346.969h-416.38z" />
            <path fill="#0078D4" d="M1362.667 1022h383.25v383.25h-383.25z" />
            <linearGradient
                id="outlook-primary_svg__a"
                x1={1128.458}
                x2={1128.458}
                y1={811.083}
                y2={1.998}
                gradientTransform="matrix(1 0 0 -1 0 1705.333)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0} stopColor="#35b8f1" />
                <stop offset={1} stopColor="#28a8ea" />
            </linearGradient>
            <path
                fill="url(#outlook-primary_svg__a)"
                d="m1811.58 927.593-.809.426-634.492 356.848c-2.768 1.703-5.578 3.321-8.517 4.769a88.4 88.4 0 0 1-34.407 8.517l-34.663-20.27a87 87 0 0 1-8.517-4.897L447.167 906.003h-.298l-21.036-11.753v722.384c.328 48.196 39.653 87.006 87.849 86.7h1230.914c.724 0 1.363-.341 2.129-.341a107.8 107.8 0 0 0 29.808-6.217 86 86 0 0 0 11.966-6.217c2.853-1.618 7.75-5.152 7.75-5.152a85.97 85.97 0 0 0 34.833-68.772V894.25a38.32 38.32 0 0 1-19.502 33.343"
            />
            <path
                fill="#0A2767"
                d="M1797.017 891.397v44.287l-663.448 456.791-686.87-486.174a.426.426 0 0 0-.426-.426l-63.023-37.899v-31.938l25.976-.426 54.932 31.512 1.277.426 4.684 2.981s645.563 368.346 647.267 369.197l24.698 14.478c2.129-.852 4.258-1.703 6.813-2.555 1.278-.852 640.879-360.681 640.879-360.681z"
                opacity={0.5}
            />
            <path
                fill="#1490DF"
                d="m1811.58 927.593-.809.468-634.492 356.848c-2.768 1.703-5.578 3.321-8.517 4.769a88.96 88.96 0 0 1-78.098 0 97 97 0 0 1-8.517-4.769l-634.49-356.848-.766-.468a38.33 38.33 0 0 1-20.057-33.343v722.384c.305 48.188 39.616 87.004 87.803 86.7h1229.64c48.188.307 87.5-38.509 87.807-86.696V894.25a38.33 38.33 0 0 1-19.504 33.343"
            />
            <path
                d="m1185.52 1279.629-9.496 5.323a93 93 0 0 1-8.517 4.812 88.2 88.2 0 0 1-33.47 8.857l241.405 285.479 421.107 101.476a86.8 86.8 0 0 0 26.7-33.343z"
                opacity={0.1}
            />
            <path
                d="m1228.529 1255.442-52.505 29.51a93 93 0 0 1-8.517 4.812 88.2 88.2 0 0 1-33.47 8.857l113.101 311.838 549.538 74.989a86.1 86.1 0 0 0 34.407-68.815v-9.326z"
                opacity={0.05}
            />
            <path
                fill="#28A8EA"
                d="M514.833 1703.333h1228.316a88.3 88.3 0 0 0 52.59-17.033l-697.089-408.331a87 87 0 0 1-8.517-4.897L447.125 906.088h-.298l-20.993-11.838v719.914c-.048 49.2 39.798 89.122 88.999 89.169q-.001 0 0 0"
            />
            <path
                d="M1022 418.722v908.303c-.076 31.846-19.44 60.471-48.971 72.392a73.4 73.4 0 0 1-28.957 5.962H425.833V383.25H511v-42.583h433.073c43.019.163 77.834 35.035 77.927 78.055"
                opacity={0.1}
            />
            <path
                d="M979.417 461.305v908.302a69.36 69.36 0 0 1-6.388 29.808c-11.826 29.149-40.083 48.273-71.54 48.417H425.833V383.25h475.656a71.5 71.5 0 0 1 35.344 8.943c26.104 13.151 42.574 39.883 42.584 69.112"
                opacity={0.2}
            />
            <path
                d="M979.417 461.305v823.136c-.208 43-34.928 77.853-77.927 78.225H425.833V383.25h475.656a71.5 71.5 0 0 1 35.344 8.943c26.104 13.151 42.574 39.883 42.584 69.112"
                opacity={0.2}
            />
            <path
                d="M936.833 461.305v823.136c-.046 43.067-34.861 78.015-77.927 78.225H425.833V383.25h433.072c43.062.023 77.951 34.951 77.927 78.013z"
                opacity={0.2}
            />
            <linearGradient
                id="outlook-primary_svg__b"
                x1={162.747}
                x2={774.086}
                y1={1383.074}
                y2={324.259}
                gradientTransform="matrix(1 0 0 -1 0 1705.333)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0} stopColor="#1784d9" />
                <stop offset={0.5} stopColor="#107ad5" />
                <stop offset={1} stopColor="#0a63c9" />
            </linearGradient>
            <path
                fill="url(#outlook-primary_svg__b)"
                d="M78.055 383.25h780.723c43.109 0 78.055 34.947 78.055 78.055v780.723c0 43.109-34.946 78.055-78.055 78.055H78.055c-43.109 0-78.055-34.947-78.055-78.055V461.305c0-43.108 34.947-78.055 78.055-78.055"
            />
            <path
                fill="#FFF"
                d="M243.96 710.631a227.05 227.05 0 0 1 89.17-98.495 269.56 269.56 0 0 1 141.675-35.515 250.9 250.9 0 0 1 131.114 33.683 225 225 0 0 1 86.742 94.109 303.75 303.75 0 0 1 30.405 138.396 320.6 320.6 0 0 1-31.299 144.783 230.37 230.37 0 0 1-89.425 97.388 260.86 260.86 0 0 1-136.011 34.578 256.36 256.36 0 0 1-134.01-34.067 228.5 228.5 0 0 1-87.892-94.28 296.5 296.5 0 0 1-30.745-136.735 329.3 329.3 0 0 1 30.276-143.845m95.046 231.227a147.4 147.4 0 0 0 50.163 64.812 131.03 131.03 0 0 0 78.353 23.591 137.24 137.24 0 0 0 83.634-24.358 141.16 141.16 0 0 0 48.715-64.812 251.6 251.6 0 0 0 15.543-90.404 275.2 275.2 0 0 0-14.649-91.554 144.8 144.8 0 0 0-47.182-67.537 129.58 129.58 0 0 0-82.91-25.55 135.2 135.2 0 0 0-80.184 23.804 148.63 148.63 0 0 0-51.1 65.365 259.76 259.76 0 0 0-.341 186.728z"
            />
            <path fill="#50D9FF" d="M1362.667 255.5h383.25v383.25h-383.25z" />
        </svg>
    ),
);
OutlookPrimaryIcon.displayName = 'OutlookPrimaryIcon';
export default OutlookPrimaryIcon;
